<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            v-if="authorID"
            cols="12"
          >
            <h3 class="mb-1">
              {{ authorID }}
            </h3>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="getAuthorData"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="6"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="getAuthorData()"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refAuthorListTable"
        class="position-relative"
        :items="userList"
        responsive
        :fields="tableColumns"
        primary-key="Author_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="getAuthorData"
      >

        <!-- Column: User -->
        <template #cell(message)="data">
          <p class="font-weight-bold mb-50">
            <b-link
              :to="{ name: 'tickets-view', params: { id: data.item.ticket_number } }"
              target="_blank"
            >
              {{ data.item.ticket_number }}
            </b-link>
          </p>
          <p class="font-weight-bold mb-50">
            {{ data.item.subject }}
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="text-truncate-list small mb-50"
            v-html="data.item.message"
          />
          <!-- eslint-enable -->
          <div
            v-if="data.item.attachments && data.item.attachments.length"
            class="small"
          >
            <div class="mb-25">
              {{ data.item.attachments.length }} Attachment{{ data.item.attachments.length > 1 ? 's' : '' }}
            </div>

            <ul>
              <li
                v-for="(attachment, index) in data.item.attachments"
                :key="index"
              >
                <b-link
                  :href="attachment.location"
                  target="_blank"
                  :class="{'mt-75': index}"
                >
                  <span class="text-muted align-text-top">{{ attachment.filename }}</span>
                  <span class="text-muted ml-25">({{ attachment.file_size }})</span>
                </b-link>
              </li>
            </ul>
          </div>
        </template>

        <!-- Column: Author -->
        <template #cell(author)="data">
          <b-link
            :to="{ name:'author-history', params: { id: data.item.author_id} }"
          >
            {{ data.item.author }}
          </b-link>
        </template>

        <!-- Column: Updated At -->
        <template #cell(updated_at)="data">
          {{ data.item.updated_at | formatDate }}
        </template>

        <!-- Column: Actions -->
        <template #cell(status)="data">
          <div
            v-if="data.item.status === 'New Ticket'"
            class="text-nowrap"
          >
            {{ data.item.status }}
          </div>
          <div
            v-else-if="data.item.status === 'Closed'"
          >
            <b-badge
              variant="light-info"
              class="mr-25"
            >
              {{ data.item.status }}
            </b-badge>
            <p class="small">
              Summary:<br>{{ data.item.resume }}
            </p>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BLink, BPagination, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Notification

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BLink,
    BBadge,

    vSelect,
  },
  setup() {
    const toast = useToast()
    const isAddNewAuthorSidebarActive = ref(false)

    // Table Handlers
    const tableColumns = [
      { key: 'message', sortable: true },
      { key: 'author', sortable: true },
      { key: 'channel', sortable: true },
      { key: 'agent', sortable: true },
      { key: 'updated_at', label: 'Last Update', sortable: true },
      { key: 'status' },
    ]

    const perPage = ref(25)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('Author_id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)

    return {
      toast,
      // Sidebar
      isAddNewAuthorSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // Extra Filters
      roleFilter,
    }
  },
  data() {
    return {
      authorID: '',
      AuthorTags: [],
      userList: [],
      dataMeta: '',
      AuthorData: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.id) {
      next('/dashboard')
    } else {
      next(vm => {
        // eslint-disable-next-line no-param-reassign
        vm.goto = (from) ? from.path : '/tickets'
      })
    }
  },
  created() {
    this.authorID = this.$route.params.id
    this.getAuthorData()
  },
  methods: {
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.getAuthorData()
    },
    getAuthorData() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        id: this.authorID,
        length: this.perPage,
        filters: [],
      }
      if (this.searchQuery) {
        params.keywords = this.searchQuery
      }
      if (this.sortBy) {
        params.sort_by = [{
          column: this.sortBy,
          asc_desc: (this.isSortDirDesc) ? 'desc' : 'asc',
        }]
      }
      let target = '/author/company'
      if (this.roleFilter) {
        params.filters.push({
          column: 'tagging_id',
          value: this.roleFilter.tagging_id,
        })
      }
      if (this.currentPage !== 1) {
        target += `?page=${this.currentPage}`
      }

      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.userList = resp.data.data
            this.dataMeta = resp.data.meta
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Author list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editData(data) {
      this.AuthorData = data
      this.isAddNewAuthorSidebarActive = true
    },
    resetAuthorData() {
      this.AuthorData = {}
    },
    confirmDel(data) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/Author/delete', { Author_id: data.Author_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getAuthorData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Deleting Data',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
